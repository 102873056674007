import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar, Link, Box, Container, Grid, Toolbar,
} from
  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavMenu from 'Components/Public/NavMenu';
import { NavLink } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import LoginButton from 'Components/Common/Login/LoginButton';
import Logo from 'Components/Common/Logo';
import LanguageSelectLinks from 'Components/Common/LanguageSelectLinks';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid lightgray',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  container: {
    width: '100%',
    height: '100%',
  },
  item: {
    width: '100%',
    height: '100%',
  },
  item2: {
    height: '100%',
  },
  spacerTop: {
    width: '100%',
    height: '100%',
    flexGrow: 4,
    margin: 0,
  },
  spacerBottom: {
    width: '100%',
    height: '100%',
    flexGrow: 6,
    margin: 0,
  },
  mobileLogo: {
    flexGrow: 1,
  },
  navLinks: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      textAlign: 'right',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showOnXS: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
    },
  },
  hideOnXS: {
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileView: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
    },
  },
  computerView: {
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  languageLinks: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navLink: {
    fontSize: '1.125rem',
    textAlign: 'center',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
  },
  languageLink: {
    color: theme.palette.text.link,
    fontFamily: '"Play", Arial, sans-serif',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      display: 'none',
    },
  },
  toolbarMobile: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.text.primary,
  },
  textMobile: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}));

const activeLinkStyle = {
  opacity: '0.5',
};

const NavTabs: FC = () => {
  const { t } = useTranslation(['main']);
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <>
      <AppBar
        position="absolute"
        className={classes.appBar}
        elevation={0}
      >
        <Container
          className={classes.container}
          disableGutters
          maxWidth="md"
        >
          <div className={classes.mobileView}>
            <Toolbar disableGutters>
              <Box
                className={classes.mobileLogo}
                height="60px"
                margin={1}
              >
                <Logo />
              </Box>
              <div className={classes.hideOnXS}>
                <Box marginRight={1}>
                  <LanguageSelectLinks />
                </Box>
              </div>
              <div className={classes.showOnXS}>
                <NavMenu />
              </div>
            </Toolbar>
            <Toolbar
              disableGutters
            >
              <Link
                className={classes.navLink}
                component={NavLink}
                exact
                to={t('routes.home')}
                color="inherit"
                activeStyle={activeLinkStyle}
              >
                {t('appbar.home')}
              </Link>
              <Link
                className={classes.navLink}
                component={NavLink}
                exact
                to={t('routes.whatCalculator')}
                color="inherit"
                activeStyle={activeLinkStyle}
              >
                {t('appbar.whatCalculator')}
              </Link>
              <Link
                className={classes.navLink}
                component={NavLink}
                to={t('routes.background')}
                color="inherit"
                activeStyle={activeLinkStyle}
              >
                {t('appbar.background')}
              </Link>
              <div className={classes.hideOnXS}>
                <Link
                  className={classes.navLink}
                  component={NavLink}
                  to={t('routes.development')}
                  color="inherit"
                  activeStyle={activeLinkStyle}
                >
                  {t('appbar.development')}
                </Link>
              </div>
              <div className={classes.hideOnXS}>
                <Link
                  className={classes.navLink}
                  component={NavLink}
                  to={t('routes.methods')}
                  color="inherit"
                  activeStyle={activeLinkStyle}
                >
                  {t('appbar.methods')}
                </Link>
              </div>
              <div className={classes.hideOnXS}>
                <Link
                  className={classes.navLink}
                  component={NavLink}
                  to="/ajankohtaista"
                  color="inherit"
                  activeStyle={activeLinkStyle}
                >
                  {t('appbar.news')}
                </Link>
              </div>
              {user && user.groups?.includes('Users') && (
              <div className={classes.hideOnXS}>
                <Link
                  className={classes.navLink}
                  component={NavLink}
                  to="/company"
                  color="inherit"
                  activeStyle={activeLinkStyle}
                >
                  {t('appbar.company')}
                </Link>

              </div>
              )}
              {user && user.groups?.includes('Admins') && (
              <div className={classes.hideOnXS}>
                <Link
                  className={classes.navLink}
                  component={NavLink}
                  to="/admin"
                  color="inherit"
                  activeStyle={activeLinkStyle}
                >
                  {t('appbar.admin')}
                </Link>

              </div>
              )}
            </Toolbar>
          </div>
          <div className={classes.computerView}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Box height="40px">
                  <Logo />
                </Box>
              </Grid>
              <Grid item>
                <LanguageSelectLinks />
              </Grid>
              <Grid
                item
              >
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      exact
                      to={t('routes.home')}
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.home')}
                    </Link>

                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      exact
                      to={t('routes.whatCalculator')}
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.whatCalculator')}
                    </Link>

                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      to={t('routes.background')}
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.background')}
                    </Link>

                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      to={t('routes.development')}
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.development')}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      to={t('routes.methods')}
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.methods')}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      to={t('routes.news')}
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.news')}
                    </Link>
                  </Grid>
                  {user && user.groups?.includes('Users') && (
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      to="/company"
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.company')}
                    </Link>
                  </Grid>
                  )}
                  {user && user.groups?.includes('Admins') && (
                  <Grid item>
                    <Link
                      className={classes.navLink}
                      component={NavLink}
                      to="/admin"
                      color="inherit"
                      activeStyle={activeLinkStyle}
                    >
                      {t('appbar.admin')}
                    </Link>
                  </Grid>
                  )}
                  <Grid item><LoginButton /></Grid>
                </Grid>
              </Grid>
            </Grid>

          </div>
        </Container>
      </AppBar>
    </>
  );
};

export default NavTabs;
