import React, {
  FC,
} from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import iconPng from '../../logo_blue.png';
import iconLabelFi from '../../logotext_fi.png';
import iconLabelSv from '../../logotext_sv.png';
import iconLabelEn from '../../logotext_en.png';
import etLogoFi from '../../et_logo_fi.jpg';
import etLogoSv from '../../et_logo_sv.jpg';
import etLogoEn from '../../et_logo_en.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  logoImage: {
    maxHeight: '40px',
    maxWidth: '72px',
    width: 'auto',
    height: '100%',
  },
  textPanel: {
    display: 'flex',
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'initial',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5em',
    },
  },
  spacerTop: {
    flexGrow: 4,
    margin: 0,
    maxWidth: '100%',
    maxHeight: '100%',
  },
  spacerBottom: {
    flexGrow: 6,
    margin: 0,
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const Logo: FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('main');

  const getSource = () => {
    switch (i18n.language) {
      case ('sv'):
        return iconLabelSv;
      case ('en'):
        return iconLabelEn;
      default:
        return iconLabelFi;
    }
  };

  const getEtLogo = () => {
    switch (i18n.language) {
      case ('sv'):
        return etLogoSv;
      case ('en'):
        return etLogoEn;
      default:
        return etLogoFi;
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.textPanel}
      >
        <div className={classes.logoWrapper}>
          <img
            className={classes.logoImage}
            src={iconPng}
            alt="icon"
          />
          <img
            style={{ marginLeft: '5px' }}
            width={230}
            height={25}
            src={getSource()}
            alt="label"
          />
        </div>
        <img
          width={150}
          height={43}
          src={getEtLogo()}
          alt="label"
        />
      </div>
    </div>
  );
};

export default Logo;
